import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../apimethods/apiClient";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  const websitedetails = useSelector((state) => state.website);
  const [cms, setcms] = useState();
  const history = useNavigate();

  useEffect(() => {
    getCMS();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getCMS = () => {
    ApiClient.get(`cms/listing`).then((res) => {
      if (res.success) {
        setcms(res.data);
      }
    });
  };

  return (
    <> 
      <footer className="footer-section">
          <div className="footer-upper">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-title">
                    <h3>Contact information</h3>
                    <p>
                      Rhoncus quam dolor enim egestas cras. Senectusquis lectus
                      pharetra diam sociis etiam magna
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-address">
                    <ul>
                      <li>
                        <a href="#">
                          <img src="/assets/img/mail.svg" alt="mail" />
                          <p>{websitedetails?.website_email}</p>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="/assets/img/phone.svg" alt="phone" />
                          <p>
                            {websitedetails?.company_dial_code} { " "}
                            {websitedetails?.company_mobile_no}
                          </p>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="/assets/img/loc.svg" alt="location" />
                          <p>{websitedetails?.company_address}</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="footer-feature">
                    <h5>Product</h5>
                    <ul>
                      {cms &&
                        cms.map((item) => {
                          if (
                            item?.categoryDetail?.name == "Product" &&
                            item?.categoryDetail?.catType == "page"
                          ) {
                            return (
                              <li
                                className="pointer"
                                onClick={(e) => {
                                  history(`/cms/${item?.slug}`);
                                }}
                              >
                                {item?.title}
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-feature">
                    <h5>Company</h5>
                    <ul>
                      {cms &&
                        cms.map((item) => {
                          if (
                            item?.categoryDetail?.name == "Company" &&
                            item?.categoryDetail?.catType == "page"
                          ) {
                            return (
                              <li
                                className="pointer"
                                onClick={(e) => {
                                  history(`/cms/${item?.slug}`);
                                }}
                              >
                                {item?.title}
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-feature">
                    <h5>Resources</h5>
                    <ul>
                      {cms &&
                        cms.map((item) => {
                          if (
                            item?.categoryDetail?.name == "Resources" &&
                            item?.categoryDetail?.catType == "page"
                          ) {
                            return (
                              <li
                                className="pointer"
                                onClick={(e) => {
                                  history(`/cms/${item?.slug}`);
                                }}
                              >
                                {item?.title}
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-feature">
                    <h5>Social</h5>
                    <ul className="social-text d-flex">
                      <li
                        className="pointer"
                        onClick={(e) => {
                          window.open(
                            `https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D`
                          );
                        }}
                      >
                       <FaSquareXTwitter />
                      </li>
                      <li
                        className="pointer ml-2"
                        onClick={(e) => {
                          window.open(
                            `https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin`
                          );
                        }}
                      >
                        <FaLinkedin />
                      </li>
                      <li
                        className="pointer ml-2"
                        onClick={(e) => {
                          window.open(`https://www.facebook.com/`);
                        }}
                      >
                        <FaFacebookSquare />
                      </li>
                      <li
                        className="pointer ml-2"
                        onClick={(e) => {
                          window.open(`https://github.com/login`);
                        }}
                      >
                       <FaGithubSquare />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-feature">
                    <h5>Legal</h5>
                    <ul>
                      {cms &&
                        cms.map((item) => {
                          if (
                            item?.categoryDetail?.name == "Legal" &&
                            item?.categoryDetail?.catType == "page"
                          ) {
                            return (
                              <li
                                className="pointer"
                                onClick={(e) => {
                                  history(`/cms/${item?.slug}`);
                                }}
                              >
                                {item?.title}
                              </li>
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-copyright">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <p
                    className="mb-0 text-center pt-3 pb-2"
                    dangerouslySetInnerHTML={{
                      __html: websitedetails?.footer_info,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </footer>
    </>
  );
};

export default Footer;
