import React, { lazy, Suspense, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import configureStore from "./redux/configureStore.prod";
import { WebsiteDetailAction } from "../src/redux/actions/WebsiteDetailAction";
import PageRoute from "./PageRoute";
import PageLayout from "./components/Layouts/PageLayout";
import DashboardLayout from "./components/Layouts/DashboardLayout";
const { store } = configureStore();

export default function Pages() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(WebsiteDetailAction());
  }, []);


  const routes = [
    {
      path: 'login',
      Component: lazy(() => import(`./pages/auth/login`))
    },
    {
      path: '/login/merchant/:slug',
      Component: lazy(() => import(`./pages/auth/login`))
    },
    {
      path: '/signup',
      Component: lazy(() => import(`./pages/Signup`))
    },
    {
      path: '/signup/customer/:slug',
      Component: lazy(() => import(`./pages/signupascustomer`))
    },
    {
      path: '/signup/guest',
      Component: lazy(() => import(`./pages/signupasguest`))
    },
    {
      path: '/signup/guest/:slug',
      Component: lazy(() => import(`./pages/signupasguest`))
    },
    {
      path: '/forgotpassword',
      Component: lazy(() => import(`./pages/auth/forgotpassword`))
    },
    {
      path: '/resetpassword',
      Component: lazy(() => import(`./pages/Resetpassword`))
    },
    {
      path: '/cart',
      Component: lazy(() => import(`./pages/cartPage`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/cart/:slug',
      Component: lazy(() => import(`./pages/cartPage`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/placeorder',
      Component: lazy(() => import(`./pages/successFullOrder`)),
      Layout: PageLayout,
    },
    {
      path: '/checkout',
      Component: lazy(() => import(`./pages/paymentprocess`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/checkout/:slug',
      Component: lazy(() => import(`./pages/paymentprocess`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/Whislist',
      Component: lazy(() => import(`./pages/wishlist/Wishlist`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/products',
      Component: lazy(() => import(`./pages/products`)),
      Layout: PageLayout,
    },
    {
      path: '/products/merchant/:slug',
      Component: lazy(() => import(`./pages/products`)),
      Layout: PageLayout,
    },
    {
      path: '/products/:slug/:id',
      Component: lazy(() => import(`./pages/products/Detail/ProductDetail`)),
      Layout: PageLayout,
    },
    {
      path: '/products/:id',
      Component: lazy(() => import(`./pages/products/Detail/ProductDetail`)),
      Layout: PageLayout,
    },
    {
      path: '/orders',
      Component: lazy(() => import(`./pages/orders`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/transaction',
      Component: lazy(() => import(`./pages/Transactions`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/transactionDetail/:id',
      Component: lazy(() => import(`./pages/Transactions/transactiondetail`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/orderDetail/:id',
      Component: lazy(() => import(`./pages/orders/Orderdetail`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/product',
      Component: lazy(() => import(`./pages/product`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/product/add',
      Component: lazy(() => import(`./pages/product/AddEditUser`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/product/edit/:id',
      Component: lazy(() => import(`./pages/product/AddEditUser`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/productDetail/:id',
      Component: lazy(() => import(`./pages/product/Productdetail`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/addcredential',
      Component: lazy(() => import(`./pages/addCredential`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/addeditcreds',
      Component: lazy(() => import(`./pages/addCredential/addEditCreds`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/addeditcreds/edit/:id',
      Component: lazy(() => import(`./pages/addCredential/addEditCreds`)),
      Layout: DashboardLayout,
    },
    {
      path: '/userdetail/:ccpid/:custid',
      Component: lazy(() => import(`./pages/CustomerSection/Profiledetail`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/users/edit/:id',
      Component: lazy(() => import(`./pages/CustomerSection/AddEditUser`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/users/add',
      Component: lazy(() => import(`./pages/CustomerSection/AddEditUser`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/customers',
      Component: lazy(() => import(`./pages/CustomerSection`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/AllCustomers',
      Component: lazy(() => import(`./pages/merchantCustomer`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/profile',
      Component: lazy(() => import(`./pages/profile`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/profile/edit',
      Component: lazy(() => import(`./pages/auth/editprofile`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/change',
      Component: lazy(() => import(`./pages/auth/changePassword`)),
      Layout: DashboardLayout,
    },
    {
      path: '/faq',
      Component: lazy(() => import(`./pages/faq`)),
      Layout: PageLayout,
    },
    {
      path: '/quiz',
      Component: lazy(() => import(`./pages/quizesPages/Quiz`)),
      Layout: PageLayout,
      auth: true
    },
    {
      path: '/blogs',
      Component: lazy(() => import(`./pages/blogs`)),
      Layout: PageLayout
    },
    {
      path: '/blog-detail/:id?',
      Component: lazy(() => import(`./pages/blogs/Detail`)),
      Layout: PageLayout
    },
    {
      path: '/home',
      Component: lazy(() => import(`./pages/home`)),
    },
    {
      path: '/home/merchant/:slug',
      Component: lazy(() => import(`./pages/home`))
    },
    {
      path: '/cms/:slug',
      Component: lazy(() => import(`./pages/CommonContentPage/CommonContentpage`))
    },
    {
      path: '/contactus',
      Component: lazy(() => import(`./pages/ContactUs`))
    },
    {
      path: '/dashboard',
      Component: lazy(() => import(`./pages/dashboard`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/chart/:slug',
      Component: lazy(() => import(`./components/common/commonchart/Chart`))
    },
    {
      path: '/payment',
      Component: lazy(() => import(`./pages/paymentPage`))
    },
    {
      path: '/services/:slug',
      Component: lazy(() => import(`./pages/internalPages/chargeback`)),
      Layout: PageLayout
    },
    {
      path: '/funds',
      Component: lazy(() => import(`./pages/funds/Funds`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/adjustments',
      Component: lazy(() => import(`./pages/funds/Adjustments`)),
      Layout: DashboardLayout,
      auth: true
    },
    {
      path: '/tokenize',
      Component: lazy(() => import(`./pages/Tokenize/Tokenize`)),
    },
  ]

  return <>
    <Suspense
      fallback={
        <div id="" className="loaderDiv">
          <div>
            <img
              src="/assets/img/loader.gif"
              alt="logo"
              className="loaderlogo"
            />
          </div>
        </div>
      }
    >
      <Router>
        <Routes>
          {routes.map(itm => {
            const Element = itm.Component
            return <Route
              exact={true}
              store={store}
              path={itm.path}
              element={<>
                <PageRoute auth={itm.auth ? true : false} url={itm.path} Layout={itm.Layout ? itm.Layout : ''}>
                  <Element />
                </PageRoute>
              </>}
            />
          })}

          <Route path="/" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </Suspense>
  </>
}