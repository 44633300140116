/*********** Reduceres defined here *********/

// import { persistCombineReducers } from 'redux-persist';
// import storage from 'redux-persist/es/storage'; // default: localStorage if web, AsyncStorage if react-native
// import { connectRouter } from 'connected-react-router';

// // reducers
// import user from './modules/user';
// import loader from './modules/loader';
// import search from './modules/search';
// import MessageCount from './modules/MessageCount'
// import TimeTrackingReducer from './modules/TimeTrackingReducer'
// import { createBrowserHistory } from 'history';
// import  PlatformfeeReducer from './modules/Platformfee';
// import website from './modules/webiste';

// /*********** History function **************/
// export const history = createBrowserHistory();

// const userPersistConfig = {
//     key: 'admin-app',
//     storage: storage,
//     blacklist: ['router', 'loader'],
// };

// export default persistCombineReducers(userPersistConfig, {
//     router: connectRouter(history),
//     loader,
//     user,
//     search,
//     MessageCount,
//     TimeTrackingReducer,
//     PlatformfeeReducer,
//     website
    
// });

import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage'; // Default storage for web
// Remove import for connectRouter and history

// Reducers
import user from './modules/user';
import loader from './modules/loader';
import search from './modules/search';
import MessageCount from './modules/MessageCount';
import TimeTrackingReducer from './modules/TimeTrackingReducer';
import PlatformfeeReducer from './modules/Platformfee';
import website from './modules/webiste';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import environment from '../../environment';

// Encryption transform
const encryptTransformF = encryptTransform({
    secretKey: environment.secretKey, // Replace with a strong secret key
    onError: function (error) {
        console.error('Encryption error:', error);
    },
});


// Persist config
const userPersistConfig = {
    key: 'lux-frontend',
    storage: storage,
    blacklist: ['loader'],
    transforms: [encryptTransformF],
};

export default persistCombineReducers(userPersistConfig, {
    // Remove 'router' from combined reducers
    loader,
    user,
    search,
    MessageCount,
    TimeTrackingReducer,
    PlatformfeeReducer,
    website
});

