import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PageRoute({children,auth,url,Layout=''}){
    const user = useSelector((state) => state.user);
    const history=useNavigate()

    let noAccess=false
    if(!user?.loggedIn&&auth) noAccess=true

     useEffect(() => {
        if (user && !user?.loggedIn&&auth) { 
          history("/");
        }
      }, [url]);

    if(noAccess){
        return <></>
    }

  return <>{Layout ? <>
    <Layout>
      {children}
    </Layout>
  </> : <>
    {children}
  </>}
  </>
}