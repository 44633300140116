import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import methodModel from "../../validations/Validations";
import Sidebar from "../sidebar";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import ApiClient from "../../../apimethods/apiClient";
import { useNavigate } from "react-router-dom";

const Html = ({
  isOpen,
  toggle,
  searchHandle,
  search,
  user,
  isOpen1,
  searchChange,
  clear,
  Logout,
}) => {
  const history = useNavigate();
  const pathname = location.pathname;
  const websitedetails = useSelector((state) => state.website);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      ApiClient.post("plaid/create-link", {}).then((res) => {
        setToken(res?.data);
      });
    }
  }, []);

  const handleClick = () => {
    if (localStorage.getItem("token")) {
      ApiClient.get("mastercard/connect-url").then((res) => {
        window.open(res?.data);
      });
    }
  };

  return (
    <nav
      component="header"
      className={
        isOpen
          ? "navbar navbar-expand-lg main-navbar min-sidebar"
          : "navbar navbar-expand-lg main-navbar"
      }
    >
      <div className="hidden" onClick={Logout} id="logoutBtn"></div>
      <a
        onClick={toggle}
        className={
          isOpen
            ? "btn barlink text-primary active"
            : "btn barlink  text-primary"
        }
      >
        <i className="fas fa-bars" />
      </a>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="logo-width" onClick={() => history("/")}>
        <img src={environment.imageapi + "img/" + websitedetails.logo} alt="" />
      </div>

      <div className="d-flex justify-content-end ml-auto align-items-center main-head">
        {pathname == "/product" ||
          pathname == "/accounts" ||
          pathname == "/transaction" ||
          pathname == "/orders" ||
          pathname == "/industry" ||
          pathname == "/masterskils" ||
          pathname == "/questions" ||
          pathname == "/assessment" ||
          pathname == "/faq" ||
          pathname == "/categorylist" ||
          pathname == "/projects" ||
          pathname == "/technologies" ||
          pathname == "/speciality" ||
          pathname == "/recruiters" ||
          pathname == "/AllCustomers" ||
          pathname.includes("/Reqrooter") ||
          pathname.includes("/content") ? (
          <form className="headerSearch ml-3" onSubmit={searchHandle}>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => searchChange(e.target.value)}
              className="Searchbar"
            ></input>
            <i
              className="fa fa-search"
              onClick={searchHandle}
              aria-hidden="true"
            ></i>
            {search ? (
              <i
                className="fa fa-times remove-icon"
                onClick={clear}
                aria-hidden="true"
              ></i>
            ) : (
              <></>
            )}
          </form>
        ) : null}
        {/* {((token && user?.role_name != "Customer") || "Guest") && (
          <div class="form-check form-switch dashboard-switch">
            <label class="form-check-label  mr-5" for="flexSwitchCheckDefault">
              Sandbox
            </label>
            <input
              class="form-check-input"
              onChange={handleModeChange}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
            <label class="form-check-label " for="flexSwitchCheckDefault">
              Live
            </label>
          </div>
        )} */}
        {token &&
          user?.role_name == "Customer" &&
          user?.role_name == "Guest" && (
            <Link to="/cart" className="d-inline ml-auto cart_header">
              <i className="fa fa-shopping-cart mr-1" /> Cart
            </Link>
          )}
        <div className="dropdown ">
          <a
            data-toggle="dropdown"
            className="nav-link dropdown-toggle nav-link-user text-dark d-flex align-items-center"
          >
            <img
              alt="image"
              src={methodModel.userImg(user.image)}
              width={40}
              height={40}
              className="rounded-circle mr-1"
            />
          </a>

          <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover prodiv_cl">
            <div className="text-center">
              <img
                alt="image"
                src={methodModel.userImg(user.image)}
                width={50}
                height={50}
                className="rounded-circle drop_innerimg mb-2"
              />
              <div className="ml-1 ">
                <b className="mt-2 user_name">{user.fullName}</b>
                <p className="dropgray mb-0 text-capitalize  customer_bx">
                  {user.role?.name}
                </p>
              </div>
            </div>
            <Link to="/" className="dropdown-item has-icon">
              <i className="fa fa-home mr-1" /> Home
            </Link>
            <Link to="/profile" className="dropdown-item has-icon">
              <i className="fa fa-user mr-1" /> Profile
            </Link>    
            <Link to="/change" className="dropdown-item has-icon ">
              <i class="fa fa-lock me-2" aria-hidden="true"></i>
              Change Password
            </Link>
            {/* {user?.role_name != "Customer" &&
              user?.role_name != "Guest" &&
              user?.active_accounts <= 0 && (
                <a
                  id="WantLogout"
                  onClick={() => handleClick()}
                  className="dropdown-item has-icon"
                >
                  <i className="fa fa-university mr-1" /> Connect a bank account
                </a>
              )} */}
            <a
              id="WantLogout"
              onClick={() => {
                Logout();
              }}
              className="dropdown-item has-icon"
            >
              <i className="fas fa-sign-out-alt mr-1" /> Logout
            </a>
          </div>
        </div>
      </div>

      {isOpen1 ? (
        <div className="w-100 mobi-dropdown">
          <Sidebar />
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default Html;
