import React from "react";
import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import configureStore from "./redux/configureStore.prod";
import "react-pagination-js/dist/styles.css";
import "./scss/main.scss";
import "react-toastify/dist/ReactToastify.css";

/************ store configration *********/
import { ToastContainer } from "react-toastify";
import { Provider } from 'react-redux';
import Pages from "./Pages";
const { persistor, store } = configureStore();

export default () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <Pages/>
        </PersistGate>
      </Provider>
     
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
