let apiUrl = process.env.REACT_APP_API_URL

const environment = {
    api: apiUrl,
    imageapi: process.env.REACT_APP_IMAGE_URL,
    withoutapi:process.env.REACT_APP_IMAGE_URL,
    adminRoleId:process.env.REACT_APP_ADMIN_ROLE_ID,
    userRoleId:process.env.REACT_APP_USER_ROLE_ID,
    map_api_key :process.env.REACT_APP_MAP_API_KEY,
    ZoomClientId:process.env.REACT_APP_ZOOM_CLIENT_ID,
    ZoomClientSecret:process.env.REACT_APP_ZOOM_CLIENT_SECRET,
    secretKey :process.env.REACT_APP_CRYPTO_SECRET_KEY,
    mode :process.env.REACT_APP_MODE,
  };
  export default environment;
