import Footer from "../global/footer/Footer";
import WebsiteHeader from "../global/websiteheader/WebsiteHeader";

export default function PageLayout({children}){
    return <>
    <div className="other-header">
    <WebsiteHeader />
    </div>
    {children}
    <Footer />
    </>
}